import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('modules/auth/AuthRouteGuard.vue'),
    children: [
      {
        path: '',
        component: () => import('components/layouts/app/MainLayout.vue'),
        children: [
          {
            path: '',
            component: () => import('modules/app/AppRouteClientHasCompaniesGuard.vue'),
            children: [
              {
                path: '',
                component: () => import('modules/ap-dashboard/DashboardPage.vue')
              }
            ]
          },
          {
            path: '',
            meta: {
              menuItem: 'invoice_automation'
            },
            children: [
              {
                path: 'invoice',
                name: 'ap-task-list',
                component: () => import('modules/ap-task/ApTaskListPage.vue')
              },
              {
                name: 'ap-task',
                path: 'ap-task/:id?',
                component: () => import('modules/ap-task/ApTaskPage.vue')
              }
            ]
          },
          {
            path: 'processed-emails',
            component: () => import('modules/processed-emails/ProcessedEmailsListPage.vue'),
            meta: {
              menuItem: 'processed_emails'
            }
          },
          {
            path: 'other-documents',
            meta: {
              menuItem: 'other_documents'
            },
            children: [
              {
                path: '',
                component: () => import('modules/other-documents/OtherDocumentListPage.vue')
              },
              {
                name: 'other-document-task',
                path: ':id',
                component: () => import('modules/other-documents/OtherDocumentPage.vue')
              }
            ]
          },
          {
            path: 'account-statements',
            meta: {
              menuItem: 'account_statements'
            },
            children: [
              {
                path: '',
                name: 'account-statements',
                component: () => import('modules/account-statement/AccountStatementListPage.vue')
              },
              {
                name: 'account-statement-task',
                path: ':id',
                component: () => import('modules/account-statement/AccountStatementPage.vue'),
                meta: {
                  waitList: true
                }
              }
            ]
          },
          {
            path: '',
            component: () => import('modules/app/AppRouteClientHasCompaniesGuard.vue'),
            children: [
              {
                path: 'data-quality',
                component: () => import('src/modules/data-quality/DqDashboard.vue'),
                meta: {
                  menuItem: 'data-quality'
                }
              }
            ]
          },
          {
            path: 'vendors',
            meta: {
              menuItem: 'vendor_setup'
            },
            component: () => import('modules/vendors/VendorsGuard.vue'),
            children: [
              {
                name: 'vendor-list',
                path: '',
                component: () => import('modules/vendors/VendorsListPage.vue')
              },
              {
                name: 'vendor-request-new',
                path: 'new',
                component: () => import('modules/vendors/VendorsRequestNewVendorPage.vue')
              },
              {
                name: 'vendor-vista-form',
                path: ':id',
                component: () => import('modules/vendors/VendorsVistaFormPage.vue')
              }
            ]
          },
          {
            path: 'management',
            component: () => import('src/modules/management/ManagementLayout.vue'),
            meta: {
              menuItem: 'management'
            },
            children: [
              {
                path: '',
                component: () => import('modules/management/clients/ClientsListPage.vue'),
                meta: {
                  sideMenuItem: 'clients'
                }
              },
              {
                path: 'clients',
                meta: {
                  sideMenuItem: 'clients'
                },
                children: [
                  {
                    path: '',
                    component: () => import('modules/management/clients/ClientsListPage.vue')
                  },
                  {
                    path: ':clientId',
                    component: () => import('modules/management/clients/ClientDetailsPage.vue')
                  }
                ]
              },
              {
                path: 'companies',
                meta: {
                  sideMenuItem: 'companies'
                },
                children: [
                  {
                    path: '',
                    component: () => import('modules/management/companies/CompaniesListPage.vue')
                  },
                  {
                    path: ':companyId',
                    component: () => import('modules/management/companies/CompanyDetailsPage.vue')
                  }
                ]
              },
              {
                path: 'users',
                meta: {
                  sideMenuItem: 'users'
                },
                children: [
                  {
                    path: '',
                    component: () => import('modules/management/users/UserListPage.vue')
                  },
                  {
                    path: ':userId',
                    component: () => import('src/modules/management/users/UserDetailsPage.vue')
                  }
                ]
              },
              {
                path: 'settings',
                meta: {
                  sideMenuItem: 'settings'
                },
                children: [
                  {
                    path: '',
                    component: () => import('modules/management/settings/SettingsListPage.vue')
                  }
                ]
              }
            ]
          },
          {
            path: 'user-emails',
            component: () => import('src/modules/user-emails/UserEmailsLayout.vue'),
            meta: {
              menuItem: 'user-emails'
            },
            children: [
              {
                path: '',
                component: () => import('modules/processed-emails/ProcessedEmailsListPage.vue'),
                meta: {
                  sideMenuItem: 'received'
                }
              },
              {
                path: 'received',
                component: () => import('modules/processed-emails/ProcessedEmailsListPage.vue'),
                meta: {
                  sideMenuItem: 'received'
                }
              },
              {
                path: 'sent',
                component: () => import('modules/user-emails/SentEmailsListPage.vue'),
                meta: {
                  sideMenuItem: 'sent'
                }
              },
              {
                path: '',
                component: () => import('modules/app/AppRouteClientHasCompaniesGuard.vue'),
                children: [
                  {
                    path: 'templates',
                    component: () => import('modules/emails/EmailTemplatesListPage.vue'),
                    meta: {
                      sideMenuItem: 'templates'
                    }
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '/demo',
        component: () => import('modules/demo/DemoLayout.vue'),
        children: [
          {
            path: '',
            component: () => import('modules/demo/FormDemo.vue')
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: () => import('modules/auth/AuthLayout.vue'),
    children: [
      {
        path: 'login',
        component: () => import('modules/auth/LoginPage.vue')
      },
      {
        path: 'forgot-password',
        component: () => import('modules/auth/ForgotPasswordPage.vue'),
        meta: {
          type: 'user-reset'
        }
      },
      {
        path: 'accept-invitation',
        component: () => import('modules/auth/AcceptInvitationPage.vue')
      },
      {
        path: 'reset-password',
        children: [
          {
            path: '',
            component: () => import('modules/auth/ResetPasswordPage.vue')
          },
          {
            name: 'admin-reset-password',
            path: ':userId',
            component: () => import('modules/auth/ForgotPasswordPage.vue'),
            meta: {
              type: 'admin-reset'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/vendor-application/:token',
    component: () => import('modules/vendors/VendorsPublicFormPage.vue')
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
];

export default routes;
